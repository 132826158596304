<template>
	<div class="payDetails">
		<div class="flexRow" style="justify-content: space-between;">
			<el-tabs v-model="listState" @tab-click="filterFun" style="flex:1">
				<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
			</el-tabs>
			<el-link href="https://jusnn6k8al.feishu.cn/docs/doccnHW9y3oGSaogp3lB3JCbldc" 
			 :underline="false" target="_blank" type="primary" class="link">查看业绩统计说明></el-link>
		</div>

		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="width: 75px;display: inline-block;text-align: right;">关键字: </label>
				<el-input v-model="searchKey" :placeholder="placeholderHold" style="width: 200px;" clearable></el-input>
			</div>
			<div class="filter-item">
				<label class="label">配送方式: </label>
				<el-select v-model="SendType" placeholder="请选择" filterable clearable style="width: 160px;margin-right: 10px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in SendTypeList" :key="index" :label="item.Name" :value="item.Id">
					</el-option>
				</el-select>
			</div>

		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" v-if="listState==0">发货时间: </label>
				<label class="label" v-if="listState==1">退款时间: </label>
				<!-- :picker-options="pickerOptions" -->
				<el-date-picker type="datetime" placeholder="选择日期"  v-model="starTime" style="width: 250px;"
				 value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期"  v-model="endTime" style="width: 250px;"
				 value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="doorListFilter">查询</el-button>
				<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container" v-if="listState==0">
			<el-table :data="groupData" v-loading="loading" ref="table">
				<el-table-column prop="OrderNo" label="订单编号" key="OrderNo">
					<template slot-scope="scope">
						<P style="color:red" v-if='scope.row.SendTypeDisplay'>[{{scope.row.SendTypeDisplay}}]</P>
						<p style="color:#409EFF;cursor: pointer;" @click='pushTorderdetail(scope.row)'>{{scope.row.OrderNo}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="BusinessOrderMoney" key="BusinessOrderMoney" label="订单发货入账（元）">
					<template slot-scope="scope">
						<div>
							<div>{{scope.row.BusinessOrderMoney.toFixed(2)}}</div>
							<div>(含运费{{scope.row.FreightMoney}}元)</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="DisplayTime" key="DisplayTime" label="发货时间" width="150"></el-table-column>
				<el-table-column prop="" label="店员" key="EmployeeName">
					<template slot-scope="scope">
						<p>{{scope.row.EmployeeName}}</p>
						<p>{{scope.row.EmployeePhone}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="ProductName" label="发货商品" width="300" key="ProductName">
					<template slot-scope="scope">
						<div class="twoSpless">
							{{scope.row.ProductName}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="SpecValue" label="规格" key="SpecDisplay">
					<template slot-scope="scope">
						<p class="oneSpless">
						<div>
							<div class="oneSpless">
								{{scope.row.SpecDisplay}}
							</div>
							<div>{{scope.row.BarCode}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ProductCount" key="ProductCount" label="发货数量"></el-table-column>
				<el-table-column prop="OrderGrossProfitMoney" key="OrderGrossProfitMoney" label="商品发货入账（元）">
					<template slot-scope="scope">
						{{scope.row.BusinessMoney.toFixed(2)}}
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<div class="table-container" v-else>
			<el-table :data="refundData" v-loading="loading" ref="table">
				<el-table-column prop="BrandName" key="BrandName2" label="退款单号">
					<template slot-scope="scope">
						<P style="color:red" v-if='scope.row.SendTypeDisplay'>[{{scope.row.SendTypeDisplay}}]</P>
						<p style="color:#409EFF;cursor: pointer;" @click='pushTorefundDetail(scope.row)'>{{scope.row.SupportNo}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="RefundMoney" key="RefundMoney2" label="退款金额">
					<template slot-scope="scope">
						<div>
							<div>{{scope.row.RealRefundMoney.toFixed(2)}}</div>
							<div>(含运费{{scope.row.RefundFreightMoney}}元)</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="CompleteTime" key="CompleteTime2" label="退款时间" width="150"></el-table-column>
				<el-table-column prop="ProductCount" label="退款数量" key="ProductCount"></el-table-column>
				<el-table-column prop="" label="店员" key="EmployeeName2">
					<template slot-scope="scope">
						<p>{{scope.row.EmployeeName}}</p>
						<p>{{scope.row.EmployeePhone}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="" label="商品" width="300" key="ProductName2">
					<template slot-scope="scope">
						<div class="twoSpless">
							{{scope.row.ProductName}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="规格" key="SpecDisplay2">
					<template slot-scope="scope">
						<div>
							<div class="oneSpless">
								{{scope.row.SpecDisplay}}
							</div>
							<div>{{scope.row.BarCode}}</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="Total1" style="margin:20px 0" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
				 :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total1">
				</el-pagination>
			</div>
		</div>


	</div>
</template>

<script>
	import {
		shoperformancedetail,
		shoprefundList,
	} from '@/api/TurnTomySelf'
	import {
		shopPerformanceorderv2,
		shopPerformanceRefundv2
	} from '@/api/goods'
	import config from '@/config/index'
	export default {
		data() {
			return {
				exportUrl: config.EXPORT_URL,
				placeholderHold:'订单编号',
				loading: false,
				listStateList: [{
						id: 0,
						value: '发货订单'
					},
					{
						id: 1,
						value: '退款单'
					}
				],
				listState: '0',
				searchKey: "",
				SendType: null,
				SendTypeList: [{
						Id: 0,
						Name: '快递发货'
					},
					{
						Id: 1,
						Name: '到店自提'
					},
					{
						Id: 2,
						Name: '同城配送'
					}
				],
				pickerOptions: {
					disabledDate(time) {
						let curDate = (new Date()).getTime();
						let three = 30 * 24 * 3600 * 1000;
						let threeMonths = curDate - three;
						return time.getTime() > Date.now() || time.getTime() < threeMonths;
					}
				},
				starTime: '',
				endTime: '',
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				groupData: [],
				//退款单
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				refundData:[],
				goUrls: config.GO_URL,
			}
		},
		beforeMount() {
			this.getNowDay()
			this.getList()
		},
		methods: {
			testtime(){
				let starTime = this.starTime
				let endTime = this.endTime
				if(!starTime||!endTime){
					this.$message.error('最多可查询92天内的订单，请重新选择时间!')
					return false
				}else{
					let time =  (new Date(endTime).getTime()-new Date(starTime).getTime())/1000/60/60/24 > 92
					if(time){
						this.$message.error('最多可查询92天内的订单，请重新选择时间!')
						return false
					}
					return true
				}
			},
			//跳转到退款单详情
			pushTorefundDetail(record){
				let OrderNoId = record.SupportNo ? record.SupportNo : 0
				let headsUrls=process.env.NODE_ENV == 'production'?'index.html#':''
				let url=this.goUrls+headsUrls+'/order/refundAfterSale/refundDetails?id='+OrderNoId				
				window.open(url)	
			},
			//跳转到订单详情
			pushTorderdetail(record){
				let OrderNoId = record.OrderNo ? record.OrderNo : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/order/orderDetailpage?Id=' + OrderNoId
				
				window.open(url)
			},
			// 获取今日时间
			getNowDay() {
				this.starTime = this.$route.query.starTime;
				this.endTime = this.$route.query.endTime;
// 				let date = new Date();
// 				let year = date.getFullYear();
// 				let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
// 				let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
// 				this.starTime = year + '-' + month + '-' + day + ' ' + '00:00:00';
// 				this.endTime = year + '-' + month + '-' + day + ' ' + '23:59:59';
			},
			async getList() {
				if(!this.testtime()) return
				try {
					// this.starTime = '2022-5-2'
					this.loading = true
					if (this.listState == 0) {
						let data = {
							Keywords: this.searchKey,
							SendType: this.SendType,
							StartTime: this.starTime,
							EndTime: this.endTime,
							ShopId: this.$route.query.Id,
							PerformanceType: 1,
							Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
							Take: this.pageSize, // 取的数据
						}
						let result = await shopPerformanceorderv2(data)
						this.Total = result.Result.Total;
						this.groupData = result.Result.Results || [];
					}
					else{
						let data = {
							Keywords: this.searchKey,
							SendType: this.SendType,
							StartTime: this.starTime,
							EndTime: this.endTime,
							ShopId: this.$route.query.Id,
							PerformanceType: 1,
							Skip: (this.currentPage1 - 1) * this.pageSize1, // 略过数据
							Take: this.pageSize1, // 取的数据
						}
						let result = await shopPerformanceRefundv2(data)
						this.Total1 = result.Result.Total;
						this.refundData = result.Result.Results || [];
					}

					this.$nextTick(()=>{
						this.$refs.table.doLayout()
					})

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			filterFun() {
				this.searchKey = ''
				this.SendType = null
				this.getNowDay()
				//结算
				if(this.listState == 0){
					this.currentPage = 1
					this.placeholderHold="订单编号"
				}
				//退款
				else{
					this.currentPage1 = 1
					this.placeholderHold="退款单号"
				}
				this.getList()
			},
			// 查询
			doorListFilter() {
				if (!this.starTime || (!this.endTime) || (new Date(this.endTime).getTime()-new Date(this.starTime).getTime())/1000/60/60/24 > 92) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多可查询92天内的订单，请重新选择时间!'
					});
					return
				}
				if(this.listState == 0){
					this.currentPage = 1
				}
				//退款
				else{
					this.currentPage1 = 1
				}
				// this.currentPage = 1
				this.getList()
			},
			exportFun() {
				if(!this.testtime()) return
				let url = this.exportUrl + (this.listState==0?'/pc/shop-performance/order-index/v2?': '/pc/shop-performance/refund-index/v2?' )+
					'&Keywords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
					'&SendType=' + (this.SendType ? this.SendType : '') +
					'&StartTime=' + (this.starTime ? this.starTime : '') +
					'&EndTime=' + (this.endTime ? this.endTime : '') +
					'&ShopId=' + this.$route.query.Id + 
					'&PerformanceType=1'
				window.open(url);
				console.log('url', url)
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			
			// 切换显示条数
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange1(val) {
				console.log(`当前页: ${val}`);
				this.currentPage1 = val;
				this.getList();
			},

		}
	}
</script>

<style lang="less" scoped>
.flexRow{
	display: flex;
	align-items: center;
		position: relative;
	.link{
			position: absolute;
			right: 0px;
			top: 15px;
		}
}
	.payDetails {
		background: #fff;
		padding: 15px;

		.twoSpless {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}

		.oneSpless {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
</style>
